import api from './index'
import { axios } from '@/utils/request'





/**
 * func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */

export function requestShipperAddressParam(values) {

    values.realname = values.shipperName
    values.phoneNumber = values.shipperPhoneNumber
    values.provinceCode = values.geoCode[0];
    values.cityCode = values.geoCode[1];
    values.districtCode = values.geoCode[2];
    values.streetCode = values.geoCode[3];
    values.addressDetail = values.shipperAddressInfo;

    delete values.geoCode;
    delete values.shipperName;
    delete values.shipperPhoneNumber;
    delete values.shipperAddressInfo;
    return values;
}

export function requestReceiverAddressParam(values) {

    values.realname = values.receiverName
    values.phoneNumber = values.receiverPhoneNumber
    values.provinceCode = values.geoCode[0];
    values.cityCode = values.geoCode[1];
    values.districtCode = values.geoCode[2];
    values.streetCode = values.geoCode[3];
    values.addressDetail = values.receiverAddressInfo;
    delete values.receiverName;
    delete values.receiverPhoneNumber;
    delete values.receiverAddressInfo;
    delete values.geoCode;
    return values
}


export function getOrderTakingList(parameter) {
    return axios({
        url: '/service_provider/order/takingorder',
        method: 'post',
        data: parameter
    })
}
export function getOrderManagerList(parameter) {
    return axios({
        url: '/service_provider/order/orderManager',
        method: 'post',
        data: parameter
    })
}

export function getOrderDetail(parameter) {
    return axios({
        url: '/service_provider/order/orderinfo',
        method: 'post',
        data: parameter
    })
}
export function orderTaking(parameter) {
    return axios({
        url: '/service_provider/order/orderReceiving',
        method: 'post',
        data: parameter
    })
}
export function saveGoods(parameter) {
    return axios({
        url: '/service_provider/order/savegoods',
        method: 'post',
        data: parameter
    })
}
export function cancelOrder(parameter) {
    console.log("cancel")
    return axios({
        url: '/service_provider/order/takeCancel',
        method: 'post',
        data: parameter
    })
}
export function onlineDisperseOrder(parameter = []) {
    return axios({
        url: '/provider/serve/onlinedisperseorder',
        method: 'post',
        data: parameter
    })
}
export function saveOrder(parameter = []) {
    return axios({
        url: '/service_provider/order/saveorder',
        method: 'post',
        data: parameter
    })
}

export function orderTransportPrice(parameter = []) {
    return axios({
        url: '/service_provider/order/ordertransportprice',
        method: 'post',
        data: parameter
    })
}
export function diffPriceOperate(parameter = []) {
    return axios({
        url: '/service_provider/order/diffPrice',
        method: 'post',
        data: parameter
    })
}
export function refundPriceOperate(parameter = []) {
    return axios({
        url: '/service_provider/order/refund',
        method: 'post',
        data: parameter
    })
}
export function saveMultiOrder(parameter = []) {
    return axios({
        url: '/service_provider/order/saveOrderCartful',
        method: 'post',
        data: parameter
    })
}
export function getLoadDispacth(parameter = []) {
    return axios({
        url: '/service_provider/order/getLoadDispacth',
        method: 'post',
        data: parameter
    })
}
export function cancelPayment(parameter = []) {
    return axios({
        url: '/service_provider/order/cancelDiffPriceOrder',
        method: 'post',
        data: parameter
    })
}
export function orderCartfulDetail(parameter = []) {
    return axios({
        url: '/service_provider/order/orderCartfulDetail',
        method: 'post',
        data: parameter
    })
}
export function verfityList(parameter = []) {
    return axios({
        url: '/service_provider/orderAudit/findListByOrderId',
        method: 'post',
        data: parameter
    })
}
export function getTltDispacth(parameter = []) {
    return axios({
        url: '/service_provider/orderapi/getTltDispacth',
        method: 'post',
        data: parameter
    })
}
export function SBAddress(parameter = []) {

    return axios({
        url: '/service_provider/dict/addressdiscriminate',
        method: 'post',
        data: parameter
    })
}
export function logisticsList(parameter = []) {

    return axios({
        url: '/service_provider/order/orderlogistics',
        method: 'post',
        data: parameter
    })
}
export function getAddressList(parameter = []) {

    return axios({
        url: '/service_provider/address/addressList',
        method: 'post',
        data: parameter
    })
}
export function getGoodsList(parameter = []) {

    return axios({
        url: '/service_provider/goods/orderGoodsList',
        method: 'post',
        data: parameter
    })
}
export function delAddress(parameter = []) {

    return axios({
        url: '/service_provider/address/deladdress',
        method: 'post',
        data: parameter
    })
}
export function delGoods(parameter = []) {
    return axios({
        url: '/service_provider/goods/delgoods',
        method: 'post',
        data: parameter
    })
}
export function quoteDetail(parameter = []) {
    return axios({
        url: '/service_provider/quotationPreview/priceDetailList',
        method: 'post',
        data: parameter
    })
}
export function getSecondaryOrderConfirmList(parameter = []) {
    return axios({
        url: '/service_provider/orderAudit/findPageSecondaryConfirmation',
        method: 'post',
        data: parameter
    })
}

export function launchSecConfirm(parameter = []) {
    return axios({
        url: '/service_provider/order/launchSecConfirm',
        method: 'post',
        data: parameter
    })
}
export function orderSecConfirm(parameter = []) {
    return axios({
        url: '/service_provider/order/orderSecConfirm',
        method: 'post',
        data: parameter
    })
}
export function orderSecConfirmCancel(parameter = []) {
    return axios({
        url: '/service_provider/order/orderSecConfirmCancel',
        method: 'post',
        data: parameter
    })
}














