
<style lang="less" scoped>
.form {
  display: flex;
  // flex-wrap: wrap;
  // flex-direction: column;
  .ant-form-item {
    //   width: 47%;
    min-height: 75px;
    //   display: flex !important;
  }
}
// .modality {
//   display: flex;
//   flex-wrap: wrap;
//   .ant-form-item {
//     width: 600px;
//   }
// }
/deep/ .ant-form-inline .ant-form-item {
  margin-right: 0 !important;
}
/deep/ .ant-form-item-label {
  width: 75px;
  text-align: left !important;
}
/deep/ .ant-form-item-control-wrapper {
  width: 50%;
}
.form1 {
  flex-direction: column;
  .ant-form-item {
    display: flex;
    min-height: 60px;
  }
  /deep/ .ant-form-item-label {
    width: 100px !important;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 70% !important;
  }
  /deep/ .ant-input {
    width: 100% !important;
  }
}
.form3 {
  flex-wrap: wrap;
  /deep/ .ant-form-item {
    width: 33% !important;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 75% !important;
  }
  /deep/ .ant-form-item-label {
   width: 75px !important;
  }
}
.form2 {
  flex-wrap: wrap;
  /deep/ .ant-form-item-control-wrapper {
    width: 75%;
  }
  .ant-form-item {
    width: 100%;
    min-height: 75px;
    /deep/ .ant-input {
      width: 100% !important;
    }
  }
}
.textarea{
  position: relative;
}
.Identify-clear{
  position: absolute;
  top: -6px;
  right: 18px;
  a{
    font-size: 12px;
  }
}
</style>
<template>
  <div>
    <a-form
      layout="inline"   
      :form="form"
      class="form"
      :class="[
        modalityShow ? 'modality' : '',
        SendTheIndex == '1' ? 'form1' : SendTheIndex == '2' ? 'form2' : SendTheIndex == '3' ? 'form3' : ''
      ]"
    >
      <a-form-item label="寄件人">
        <a-input v-decorator="['shipperName', { rules: [{ required: true, message: '寄件人' }] }]" placeholder="10字以内"  :maxLength="10"/>
      </a-form-item>
      <a-form-item label="联系方式">
        <a-input
          name="shipperPhoneNumber"
          v-decorator="['shipperPhoneNumber', { rules: [{ required: true, message: '联系方式' },{pattern:/^((1[0-9]{10})|(0\d{2,3}-\d{7,8})|(\d{7})|(\d{8}))$/, message:'请输入正确的联系方式'}] }]"
        />
      </a-form-item>

      <a-form-item label="地区">
        <a-cascader
          name="geoCode"
          placeholder="请选择城市区域"
          v-decorator="['geoCode', { rules: [{ required: true, message: '请选择城市区域' }] }]"
          :options="geoCascaderList"
        />
      </a-form-item>
      <a-form-item label="详细地址">
        <a-textarea
          class="textarea"
          auto-size
          v-decorator="['shipperAddressInfo', { rules: [{ required: true, message: '详细地址' }] }]"
        :maxLength="150" placeholder="150字以内" />
        <!-- <a-input style="width: 100%" /> -->
      </a-form-item>

    

    <a-form-item layout="inline" label="保存到地址簿" v-if="addressBool">
        
          <a-radio-group      
            v-decorator="['isShipperAddress', { initialValue: 1, rules: [{ required: true }] }]" style="margin-left:5px;"
          >
        <a-radio :key="index" v-for="(item, index) in isShipperAddressData" :value="item.enumValue">
          {{ item.enumName }}</a-radio
        >
      </a-radio-group>
    </a-form-item>

      <a-form-item label="地址识别">
        <a-textarea
          class="textarea"
          auto-size
          v-decorator="['addressText', { rules: [{ message: '地址识别' }] }]"
        />
        <div class="Identify-clear">
         <a @click="SBAddress()">识别</a>&nbsp;<a @click="SBAddressClear()">清空</a>
        </div>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { geoCascaderList } from '@/api/quotation'
import { SBAddress } from '@/api/order'
export default {
  name: 'SendForm',
  props: {   
    modalityShow: {
      type: Boolean,
      default: false,
    },
    SendTheIndex: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      geoCascaderList: [],
      isShipperAddressData:[],
      addressBool:false,
      form: this.$form.createForm(this),
    }
  },
  mounted() {     
    this.form.resetFields()
    const parameter = { showDistrict:1 ,showStreet: 1}
    geoCascaderList(parameter)
      .then((res) => {
        console.log(res)
        this.geoCascaderList = res.result
      })
      .catch((e) => {
        console.info(e)
      })
  },

  created() {},
  methods: {
    send(){      
      this.form.resetFields()
    },   
    sendLoad(res = []){      
      if( res.orderAssistInfoSave != undefined){
        this.addressBool = true
        this.isShipperAddressData = res.orderAssistInfoSave
      }      
    },
    SBAddress(){
      var addressText = this.form.getFieldValue('addressText')
      var parameter = {"addressText":addressText};
      SBAddress(parameter)
      .then((res) => {
        console.log(res)
        if(res.code == 0){
          var record = res.result;
          this.form.setFieldsValue({
            shipperName: record.realname,
            shipperPhoneNumber: record.phoneNumber,
            geoCode: [record.provinceCode, record.cityCode, record.districtCode,record.streetCode],
            shipperAddressInfo: record.addressDetail,            
          })         
        }else{
           this.$notification.error({
                message: '识别错误',
                description: res.message,
           })
        }        
      })
      .catch((e) => {
        console.info(e)
      })
    },
    SBAddressClear(){
        this.form.setFieldsValue({
            addressText: '',                    
        })
    }
  },
}
</script>
