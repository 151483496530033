<style lang="less" scoped>
/deep/ .ant-form-item {
  min-height: 60px !important;
}
/deep/ .ant-form-item-control-wrapper {
  width: 50%;
}
.form {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-form-item {
    /deep/ .ant-input {
      width: 100%;
    }
  }
}
.ant-card-body {
  height: 0 !important;
}
/deep/ .ant-form-item-label {
  width: 75px;
  text-align: left !important;
}
.form1 {
  /deep/ .ant-form-item-label {
    width: 100px;
  }
  /deep/ .ant-form-item {
    width: 100%;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 70%;
  }
  /deep/ .ant-input {
    width: 100%;
  }
}
.form3 {
  /deep/ .ant-form-item {
    width: 100%;
    margin-right: 0 !important;
  }
  /deep/ .ant-form-item-control{
    width: 100%;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 75%;
    /deep/ .ant-input {
      width: 100%;
    }
  }
}
.form2 {
  /deep/ .ant-form-item {
    width: 100%;
    margin-right: 0 !important;
  }
  /deep/ .ant-form-item-label {
    width: 100px;
  }
  /deep/ .ant-form-item-control{
    width: 100%;
  }
  /deep/ .ant-form-item-control-wrapper {
    width: 60%;
    /deep/ .ant-input {
      width: 100%;
    }
  }
}
.textarea{
  position: relative;
}
.Identify-clear{
  position: absolute;
  top: -6px;
  right: 18px;
    a{
    font-size: 12px;
  }
}
</style>
<template>
  <a-form
    layout="inline"   
    :form="form"
    class="form"
    :class="[debit == '1' ? 'form1' : debit == '2' ? 'form2' : debit == '3' ? 'form3' : '']"
    style="width: 100%"
  >
    <a-form-item label="收件人">
      <a-input v-decorator="['receiverName', { rules: [{ required: true, message: '收件人' }] }]" placeholder="10字以内" :maxLength="10"/>
    </a-form-item>
    <a-form-item label="联系方式">
      <a-input v-decorator="['receiverPhoneNumber', { rules: [{ required: true, message: '联系方式' },{pattern:/^((1[0-9]{10})|(0\d{2,3}-\d{7,8})|(\d{7})|(\d{8}))$/, message:'请输入正确的联系方式'}] }]" />
    </a-form-item>
    <a-form-item label="地区">
      <a-cascader
        name="geoCode"
        placeholder="请选择城市区域"
        v-decorator="['geoCode', { rules: [{ required: true, message: '请选择城市区域' }] }]"
        :options="geoCascaderList"
      />
    </a-form-item>
    <a-form-item label="详细地址">
      <a-textarea
        class="textarea"
        auto-size
        v-decorator="['receiverAddressInfo', { rules: [{ required: true, message: '详细地址' }] }]"
      :maxLength="150" placeholder="150字以内"/>
    </a-form-item>

    <a-form-item layout="inline" label="保存到地址簿" v-if="addressBool">
      <a-radio-group      
        v-decorator="['isReceiverAddress', { initialValue: 1, rules: [{ required: true }] }]" style="margin-left:5px;"
      >
        <a-radio :key="index" v-for="(item, index) in isRecipientAddressData" :value="item.enumValue">
          {{ item.enumName }}</a-radio
        >
      </a-radio-group>
    </a-form-item>

    <a-form-item label="地址识别">
        <a-textarea
          class="textarea"
          auto-size
          v-decorator="['addressText', { rules: [{ message: '地址识别' }] }]"
        />         
        <div class="Identify-clear">
         <a @click="SBAddress()">识别</a>&nbsp;<a  @click="SBAddressClear()">清空</a>  
        </div>     
  
      </a-form-item>

  </a-form>
</template>
<script>
import { geoCascaderList } from '@/api/quotation'
import { SBAddress } from '@/api/order'
export default {
  name: 'RecipientForm',
  props: {
   
    debit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      geoCascaderList: [],
      isRecipientAddressData:[],
      addressBool:false,
      form: this.$form.createForm(this),
    }
  },
  mounted() {
    const parameter = { showDistrict:1 ,showStreet: 1}
    geoCascaderList(parameter)
      .then((res) => {        
        this.geoCascaderList = res.result
      })
      .catch((e) => {
        console.info(e)
      })
  },
  methods: {
    recipient(){      
      this.form.resetFields()
    },
    recipientLoad(res){
       if( res.orderAssistInfoSave != undefined){
            this.addressBool = true
            this.isRecipientAddressData = res.orderAssistInfoSave
       }       
    },
    load(record) {
      const {
        form: { setFieldsValue },
      } = this
      this.$nextTick(() => {
        record.geoCode = [record.provinceCode, record.cityCode, record.districtCode,record.streetCode]
        setFieldsValue(pick(record, ['receiverName', 'receiverPhoneNumber', 'geoCode', 'receiverAddressInfo']))
      })
    },   

    SBAddress(){
      var addressText = this.form.getFieldValue('addressText')
      var parameter = {"addressText":addressText};
      SBAddress(parameter)
      .then((res) => {
        console.log(res)
        if(res.code == 0){
          var record = res.result;
          this.form.setFieldsValue({
            receiverName: record.realname,
            receiverPhoneNumber: record.phoneNumber,
            geoCode: [record.provinceCode, record.cityCode, record.districtCode,record.streetCode],
            receiverAddressInfo: record.addressDetail,            
          })         
        }else{
           this.$notification.error({
                message: '识别错误',
                description: res.message,
           })
        }        
      })
      .catch((e) => {
        console.info(e)
      })
    },
    SBAddressClear(){
        this.form.setFieldsValue({
            addressText: '',                    
        })
    }


  },
}
</script>
