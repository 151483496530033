import { axios } from '@/utils/request'

const api = {
    enumeration: '/service_provider/dict/getDictByTableName',
}

export default api

export function enumerationData(parameter) {
    return axios({
        url: api.enumeration,
        method: 'post',
        data: parameter
    })
}




